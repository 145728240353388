<template>
  <div>
    <div class="my-1">
      <!-- title -->
      <p
        style="font-size: 28px; font-weight: 700"
        class="mt-3"
      >
        Additions Catalogue
      </p>
      <!-- published details -->
      <div class="row">
        <div class="col-6">
          <div class="d-flex">
<!--            <p>Last Published</p>-->
<!--            <p class="ml-1 text-muted">-->
<!--              v2024-02-07-->
<!--            </p>-->
          </div>
        </div>
        <div class="col-6 text-right">
          <!--          <button class="btn btn-primary">-->
          <!--            Publish-->
          <!--            <uil-message-->
          <!--              class="logo"-->
          <!--              size="15px"-->
          <!--            />-->
          <!--          </button>-->
        </div>
      </div>
      <!-- start:: tabs -->
      <b-tabs>
        <!-- start:: Addition sets -->
        <b-tab
          active
          title="Additions Sets"
        >
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="d-flex justify-content-end mr-2 mt-3">
                  <input
                    v-model="search_spec_set"
                    type="text mr-1"
                    class="form form-control"
                    style="width: 250px"
                    placeholder="Search"
                  >
                  <button
                    class="btn btn-primary ml-2"
                    @click="display_create_modal = true"
                  >
                    Add New Addition Set
                  </button>
                </div>
                <v-data-table
                  :headers="headers_spec_sets"
                  :items="specification_sets"
                  sort-by="created_at"
                  class="elevation-1 m-2"
                  :search="search_spec_set"
                  :items-per-page="items_per_page"
                >
                  <template v-slot:item.actions="{ item }">
                    <div class="ml-2">
                      <a @click="goToEdit(item.id)">
                        <v-icon> mdi-eye</v-icon>
                      </a>
                      <v-icon @click="openDeleteSpecSet(item)">
                        mdi-trash-can
                      </v-icon>
                    </div>
                  </template>
                  <template v-slot:item.created_at="{ item }">
                    <div>
                      {{ formatDatetime(item.created_at) }}
                    </div>
                  </template>
                  <template v-slot:item.updated_at="{ item }">
                    <div>
                      {{ formatDatetime(item.updated_at) }}
                    </div>
                  </template>
                  <template v-slot:no-data>
                    There are not Addition sets to show
                  </template>
                </v-data-table>
              </div>
            </div>
            <div
              v-if="false"
              class="col-3"
            >
              <div class="card">
                <div class="m-1">
                  <h4 class="ml-1 my-2">
                    Activity Log
                  </h4>
                  <LogCard :activity_logs="fake_activity_logs" />
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <!-- end:: Addition sets -->
        <!-- start:: Addition -->
        <b-tab title="Additions">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="d-flex justify-content-end mr-2 mt-3">
                  <input
                    v-model="search_spec_set_item"
                    type="text"
                    class="form form-control"
                    style="width: 250px"
                    placeholder="Search"
                  >
                  <button
                    class="btn btn-primary ml-2"
                    @click="openCreateSpecSetItem"
                  >
                    Add New Spec
                  </button>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="specification_items"
                  sort-by="created_at"
                  class="elevation-1 m-2"
                  :search="search_spec_set_item"
                  :items-per-page="items_per_page"
                >
                  <template
                    v-for="(col, i) in filters"
                    v-slot:[`header.${i}`]="{ header }"
                  >
                    <div class="d-flex">
                      <div
                        :key="i"
                        style="display: inline-block; padding: 16px 0"
                      >
                        {{ header.text }}
                      </div>
                      <div
                        id="attachId"
                        :key="'filter' + i"
                        style="float: right; margin-top: 8px"
                      >
                        <v-menu
                          :close-on-content-click="false"
                          :max-width="400"
                          :min-width="300"
                          bottom
                          right
                          attach="#attachId"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                small
                                :style="
                                  activeFilters[header.value] &&
                                    activeFilters[header.value].length <
                                    filters[header.value].length
                                    ? 'color:black'
                                    : 'color:#dedede'
                                "
                              >
                                mdi-filter-variant
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list class="pa-0">
                            <v-list-item-group>
                              <template v-for="item in filters[header.value]">
                                <v-list-item
                                  :key="`${item}`"
                                  :value="item"
                                  :ripple="false"
                                >
                                  <template>
                                    <v-list-item-action>
                                      <b-form-checkbox
                                        v-model="activeFilters[header.value]"
                                        :value="item"
                                      />
                                    </v-list-item-action>
                                    <v-list-item-content>

                                      <v-list-item-title
                                        style="font-weight: normal; text-transform: none"
                                      >{{ getBudgetName(item) }} </v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                          <v-row
                            no-gutters
                            style="background-color: #fff"
                          >
                            <v-col cols="6">
                              <b-button
                                block
                                variant="outline-light"
                                style="border-color: transparent; color:  rgba(92, 177, 231, 1) "
                                @click="toggleAll(header.value)"
                              >Select all</b-button>
                            </v-col>
                            <v-col cols="6">
                              <b-button
                                block
                                variant="outline-light"
                                style="border-color: transparent; color:  rgba(92, 177, 231, 1) "
                                @click="clearAll(header.value)"
                              >Deselect all</b-button>
                            </v-col>
                          </v-row>
                        </v-menu>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.budget_group="{ item }">
                    <div>
                      {{
                        item.budget_group
                      }}
                    </div>
                  </template>
                  <template v-slot:item.total_price="{ item }">
                    {{
                      Number(item.total_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </template>
                  <template v-slot:item.element="{ item }">

                    {{ item.budget_group=='CNST'? item.construction_work.construction_spec : item.element.element_name }}
                  </template>
                  <template v-slot:item.total_cost="{ item }">
                    {{
                      Number(item.total_cost).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon @click="showEditModal(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon @click="openDeleteAdditionItem(item)">
                      mdi-trash-can
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    There are not Specs to show
                  </template>
                </v-data-table>
              </div>
            </div>
            <div
              v-if="false"
              class="col-3"
            >
              <div class="card">
                <div class="m-1">
                  <h4 class="ml-1 my-2">
                    Activity Log
                  </h4>
                  <LogCard :activity_logs="fake_activity_logs" />
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <!-- end:: Addition  -->
      </b-tabs>
      <!-- end:: tabs -->
    </div>
    <!-- start:: Addition sets modal -->
    <b-modal
      v-model="display_create_modal"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      title="New Addition Set"
      size="lg"
      centered
    >
      <form-wizard-catalogue-vue @save="saveSpecificationAddition" />
    </b-modal>
    <!-- end:: Addition sets modal -->
    <!--    start:: Addition set item modal-->
    <b-modal
      v-model="display_create_item_modal"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      title="New Addition"
      size="lg"
      centered
    >
      <SpecAdditionItemForm
        :spec-item="itemToSave"
        :base_items="base_spec_items"
        @saveItem="saveAdditionItem"
      />
    </b-modal>
    <!--    end:: Addition set item modal-->
    <!--    start:: delete Addition item modal-->
    <b-modal
      v-model="display_delete_item_modal"
      title="Delete Addition"
      centered
      @ok="confirmDeleteAdditionItem"
    >
      <h5>This action will remove the addition, <b>it will not be accessible in any future addition set or quote creation,</b> this action cannot be reversed.</h5>
    </b-modal>
    <!--    end:: delete Addition item modal-->
    <!--    start:: delete Addition set-->
    <b-modal
      v-model="display_delete_set_modal"
      title="Delete Addition Set"
      centered
      @ok="confirmDeleteSpecSet"
    >
      <h5>This action will remove the addition set, <b>it will no be accessible in any future quote creation,
        all previous quotes will not be affected</b>, this action cannot be reversed.</h5>
      <p>
        The additions attached to it will not be affected.
      </p>
    </b-modal>
    <!--    end:: delete Addition set-->
    <!--    start:: edit addition modal
-->
    <b-modal
      v-model="display_edit_modal"
      title="Edit Addition Set"
      size="lg"
      centered
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <SpecAdditionItemEditForm
        :addition-item="itemToSave"
        :base_items="base_spec_items"
        @saveItem="saveAdditionItem"
      />
    </b-modal>
    <!--    end::edit addition modal-->
  </div>
</template>

<script>
import {
  BTab, BTabs, BModal, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import { UilMessage } from '@iconscout/vue-unicons'
import {
  VDataTable,
  VIcon,
  VCol,
  VMenu,
  VBtn,
  VList,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemTitle,
  VListItemGroup,
  VRow,
} from 'vuetify/lib'
import LogCard from '../ActiveProject/Components/LogCard.vue'
import FormWizardCatalogueVue from './Utils/FormCatalogue.vue'
import SpecAdditionItemForm from '@/views/AdditionsCatalogue/Utils/SpecAdditionItemForm.vue'
import SpecAdditionItemEditForm from '@/views/AdditionsCatalogue/Utils/SpecAdditionItemEditForm.vue'

export default {
  components: {
    SpecAdditionItemEditForm,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    LogCard,
    UilMessage,
    VDataTable,
    VIcon,
    VCol,
    VMenu,
    VBtn,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VListItemGroup,
    VRow,
    BModal,
    FormWizardCatalogueVue,
    SpecAdditionItemForm,
  },
  data() {
    return {
      headers: [
        { text: 'Addition', value: 'name' },
        {
          text: 'Budget group',
          value: 'budget_group',
          sortable: false,
          filter: value => (this.activeFilters.budget_group
            ? this.activeFilters.budget_group.includes(value)
            : false),
        },
        { text: 'total COGS', value: 'total_cost' },
        { text: 'total Price', value: 'total_price' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headers_spec_sets: [
        { text: 'Addition Set', value: 'name' },
        { text: 'Created', value: 'created_at' },
        { text: 'Last Updated', value: 'updated_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald',
        },
        {
          isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw',
        },
        {
          isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson',
        },
        {
          isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney',
        },
      ],
      fake_activity_logs: [
        {
          id: 5432,
          quote_id: 1213,
          description:
              'Quote Duplicated new CSV from Quote ID: 935 - Original TFR: 0493-Rdc3.00 New TFR: 0493-RSystemOutage',
          responsible: 'Wilmar Osorio',
          payload:
              '{"g1": "0", "w1": "0", "w2": "0", "file": {}, "sqft": "10000", "newTfr": "643", "tfr_id": null, "location": "Montréal", "userName": "Wilmar Osorio", "oldQuoteId": "935", "plannerNotes": null, "opportunityID": "200", "demolitionReferenceImage": null}',
          color_variant: 'primary',
          created_at: '2024-05-01T00:00:43.000000Z',
          updated_at: '2024-05-01T00:00:43.000000Z',
        },
        {
          id: 5441,
          quote_id: 1213,
          description:
              'Active Revision replaced - Quote TFR: 0493-RSystemOutage Quote Id:1213',
          responsible: 'Wilmar Osorio',
          payload: null,
          color_variant: 'info',
          created_at: '2024-05-01T15:22:43.000000Z',
          updated_at: '2024-05-01T15:22:43.000000Z',
        },
        {
          id: 5442,
          quote_id: 1213,
          description: 'Addition created - Id: 3532',
          responsible: 'Wilmar Osorio',
          payload:
              '{"id": 3532, "name": "", "details": null, "quantity": -14, "quote_id": 1213, "uniformat": "C1010.90", "unit_cost": 0, "created_at": "2024-05-01T15:23:13.000000Z", "total_cost": -118.3, "unit_price": 0, "updated_at": "2024-05-01T15:23:13.000000Z", "tender_code": "0202", "total_price": -118.3, "budget_group": "Construction", "is_base_spec": true, "is_spec_swap": false, "cost_per_sqft": -0.01183, "internal_note": "https://docs.google.com/spreadsheets/d/1iHLhi454_q4Y6lcOAO3EyEbOqZHYXe3uaPG0kPC1Cj8/edit?usp=sharing", "is_adjustment": true, "price_per_sqft": -0.01183, "construction_work_id": 6}',
          color_variant: 'primary',
          created_at: '2024-05-01T15:23:13.000000Z',
          updated_at: '2024-05-01T15:23:13.000000Z',
        },
        {
          id: 5443,
          quote_id: 1213,
          description: 'Addition created - Id: 3533',
          responsible: 'Wilmar Osorio',
          payload:
              '{"id": 3533, "name": "", "details": null, "quantity": -6429.5, "quote_id": 1213, "uniformat": null, "unit_cost": 0, "created_at": "2024-05-01T15:23:13.000000Z", "total_cost": -22503.25, "unit_price": 0, "updated_at": "2024-05-01T15:23:13.000000Z", "tender_code": "0301", "total_price": -22503.25, "budget_group": "Construction", "is_base_spec": true, "is_spec_swap": false, "cost_per_sqft": -2.250325, "internal_note": "6,429.50", "is_adjustment": true, "price_per_sqft": -2.250325, "construction_work_id": 10}',
          color_variant: 'primary',
          created_at: '2024-05-01T15:23:13.000000Z',
          updated_at: '2024-05-01T15:23:13.000000Z',
        },
        {
          id: 5444,
          quote_id: 1213,
          description: 'Addition created - Id: 3534',
          responsible: 'Wilmar Osorio',
          payload:
              '{"id": 3534, "name": "", "details": null, "quantity": -146, "quote_id": 1213, "uniformat": "D5040.20", "unit_cost": 0, "created_at": "2024-05-01T15:23:13.000000Z", "total_cost": -43800, "unit_price": 0, "updated_at": "2024-05-01T15:23:13.000000Z", "tender_code": "0401", "total_price": -43800, "budget_group": "Construction", "is_base_spec": true, "is_spec_swap": false, "cost_per_sqft": -4.38, "internal_note": "6,429.50", "is_adjustment": true, "price_per_sqft": -4.38, "construction_work_id": 29}',
          color_variant: 'primary',
          created_at: '2024-05-01T15:23:13.000000Z',
          updated_at: '2024-05-01T15:23:13.000000Z',
        },
        {
          id: 5445,
          quote_id: 1213,
          description: 'Addition created - Id: 3535',
          responsible: 'Wilmar Osorio',
          payload:
              '{"id": 3535, "name": "", "details": null, "quantity": 0, "quote_id": 1213, "uniformat": "C1010.10", "unit_cost": 1000, "created_at": "2024-05-01T15:23:13.000000Z", "total_cost": 56000, "unit_price": 0, "updated_at": "2024-05-01T15:23:13.000000Z", "tender_code": "0101", "total_price": 0, "budget_group": "Construction", "is_base_spec": true, "is_spec_swap": false, "cost_per_sqft": 5.6, "internal_note": "443,352.58", "is_adjustment": true, "price_per_sqft": 0, "construction_work_id": 1}',
          color_variant: 'primary',
          created_at: '2024-05-01T15:23:13.000000Z',
          updated_at: '2024-05-01T15:23:13.000000Z',
        },
        {
          id: 5446,
          quote_id: 1213,
          description: 'Quote Updated - ID: 1213',
          responsible: 'Wilmar Osorio',
          payload: '{"contingency_fee_percentage": "20"}',
          color_variant: 'warning',
          created_at: '2024-05-01T15:23:13.000000Z',
          updated_at: '2024-05-01T15:23:13.000000Z',
        },
      ],
      specification_sets: [],
      display_create_modal: false,
      display_create_item_modal: false,
      display_delete_item_modal: false,
      display_delete_set_modal: false,
      display_edit_modal: false,
      specification_items: [],
      search_spec_set: '',
      search_spec_set_item: '',
      itemToSave: {
        id: null,
        name: null,
        budget_group: 'FF&E',
        is_base_spec: false,
        total_cost: 0,
        total_price: 0,
        cost_per_sqft: 0,
        price_per_sqft: 0,
        unit_cost: 0,
        unit_price: 0,
        quantity: 0,
        is_adjustment: false,
        construction_work_id: null,
        internal_note: null,
        is_site_condition: false,
        is_spec_swap: false,
        uniformat: null,
        details: null,
        tender_code: null,
      },
      // filter variables
      filters: {
        budget_group: [],
      },
      totalRows: 0,
      activeFilters: [],
      itemToDelete: [],
      // elements variables
      headersElement: [
        {
          value: 'name',
          text: 'Element',
          sortable: true,
        },
        {
          value: 'specification_key',
          text: 'Spec',
          sortable: true,
        },
        {
          text: 'Budget Group',
          value: 'budget_group',
          sortable: false,
          filter: value => (this.elementActiveFilters.budget_group
            ? this.elementActiveFilters.budget_group.includes(value)
            : false),
        },
        {
          value: 'unit_cost',
          text: 'COGS / Unit',
          sortable: true,
        },
        {
          value: 'unit_price',
          text: 'Price / Unit',
          sortable: true,
        },
        // {
        //   value: 'unit_delivery_w_install',
        //   text: 'UNIT DELIVERY INSTALL',
        //   sortable: true,
        // },
        {
          value: 'total_price',
          text: 'Total Price (w/ D&I)',
          sortable: true,
        },
      ],
      elements: [],
      filter: null,
      isBusy: false,
      perPage: 15,
      currentPage: 1,
      totalRowsElements: 1,
      elementActiveFilters: [],
      filtersElement: {
        budget_group: [],
      },
      //   define items per page
      items_per_page: 25,
      //   add base Addition items
      base_spec_items: {
        cnst: [],
        ffe: [],
        avit: [],
      },
    }
  },
  computed: {
    rows() {
      return this.totalRows
    },
    total_price() {
      return Number(
        Number(this.form.cogs_per_unit) * (1 + Number(this.form.markup) / 100),
      )
    },
  },
  mounted() {
    this.getAdditionSets()
    this.getAdditionSetItems()
  },
  methods: {
    getAdditionSets() {
      this.$http
        .get('/additions/sets')
        .then(response => {
          const { data } = response
          this.specification_sets = data
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    getAdditionSetItems() {
      this.$http
        .get('/additions/items')
        .then(response => {
          const { data } = response
          this.specification_items = data
          this.totalRows = this.specification_items
          let col = ''
          for (col in this.filters) {
            this.filters[col] = this.totalRows// eslint-disable-next-line no-loop-func
              .map(value => value[col])
              .filter((value, index, _arr) => _arr.indexOf(value) === index)
              .sort()
          }
          this.activeFilters = { ...this.filters }
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    saveSpecificationAddition(spec_set) {
      this.$http
        .post('/additions/sets', spec_set)
        .then(response => {
          this.getAdditionSets()
          this.getAdditionSetItems()
          this.showToast('success', 'Addition added')
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
      this.display_create_modal = false
    },
    goToEdit(id) {
      this.$router.push(`/catalogue-addition/${id}/edit`)
    },
    // * open edit Addition set item
    openCreateSpecSetItem() {
      this.itemToSave = {
        budget_group: 'CNST',
        cogs_per_unit: 0,
        construction_work_id: null,
        id: null,
        manufacturer: null,
        markup: 10,
        name: null,
        price_per_unit: 0,
        specification_swap_set_id: 5,
        uid: null,
        unit_delivery_install: 22.5,
      }
      this.display_create_item_modal = true
    },
    openUpdateItem(item) {
      window.location.href = `/catalogue/${item.id}/edit-item`
    },
    async saveAdditionItem(item) {
      if (item.id !== null) {
        this.$http
          .put(`/additions/items/${item.id}`, item)
          .then(response => {
            this.getAdditionSets()
            this.getAdditionSetItems()
            this.showToast('success', 'Addition Saved')
          })
          .catch(error => {
            this.show = false
            this.showToast('danger', error.response.data.message)
          })
      } else {
        this.$http
          .post('/additions/items', item)
          .then(response => {
            this.getAdditionSets()
            this.getAdditionSetItems()
            this.showToast('success', 'Addition saved')
          })
          .catch(error => {
            this.show = false
            this.showToast('danger', error.response.data.message)
          })
      }
      this.display_edit_modal = false
      this.display_create_item_modal = false
    },
    openDeleteAdditionItem(item) {
      this.itemToSave = item
      this.display_delete_item_modal = true
    },
    openDeleteSpecSet(item) {
      this.itemToDelete = item
      this.display_delete_set_modal = true
    },
    confirmDeleteAdditionItem() {
      this.$http
        .delete(`/additions/items/${this.itemToSave.id}`)
        .then(response => {
          this.getAdditionSets()
          this.getAdditionSetItems()
          this.showToast('success', 'Addition deleted', 'Success')
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    confirmDeleteSpecSet() {
      this.$http
        .delete(`/additions/sets/${this.itemToDelete.id}`)
        .then(response => {
          this.getAdditionSets()
          this.getAdditionSetItems()
          this.showToast('success', 'Addition Set deleted', 'Success')
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    getBudgetName(name) {
      switch (name) {
        case 'AVIT':
          return 'AV/IT'
        case 'FF&E':
          return 'FF&E'
        default:
          return 'Construction'
      }
    },
    toggleAll(col) {
      this.activeFilters[col] = this.specification_items
        .map(value => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort()
    },
    clearAll(col) {
      this.activeFilters[col] = []
    },
    showEditModal(item) {
      this.itemToSave = item
      this.display_edit_modal = true
    },
    toggleAllElements(col) {
      this.elementActiveFilters[col] = this.elements
        .map(value => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort()
    },
    clearAllElements(col) {
      this.elementActiveFilters[col] = []
    },
    formatDatetime(date) {
      return this.$dateF.formatDate(date)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
}

.v-data-table::v-deep td {
  font-size: 14px !important;
  font-weight: 500;
}
.v-data-table::v-deep .v-data-footer__select{
  display: none !important;
}
</style>
<style scoped scoped>
.v-data-table >>> .v-data-table-header {
  background-color: rgba(92, 177, 231, 0.12) !important;
  text-transform: uppercase;
  color: black !important;
  font-size: 50px !important;
}
</style>
<style scoped>
@import "./reset.css";
</style>
